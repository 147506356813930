import ROUTE from '@/constants/route'

const THEME = {
  DARK: 'dark',
  WHITE: 'white'
}

const EVENT_BUS = {
  LANGUAGE_TOGGLE_ENABLED: 'LANGUAGE_TOGGLE_ENABLED'
}

const GITHUB_NAME = 'peng-hf'

export { ROUTE, THEME, EVENT_BUS, GITHUB_NAME }
