var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline full-width"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"title__text"},[_vm._v(_vm._s(_vm.$t('about.timeline.title')))])]),_vm._l((_vm.$t('about.timeline.events')),function(event,idx){return _c('div',{key:idx,staticClass:"event"},[_c('div',{class:[
        'event__date-location',
        { 'first-column': idx % 2 === 0, 'third-column': idx % 2 !== 0 }
      ]},[_c('div',{staticClass:"event__date"},[_vm._v(_vm._s(event.date))]),_c('div',{staticClass:"event__location"},[_vm._v(_vm._s(event.city))])]),_c('div',{staticClass:"event__time second-column"},[_c('div',{staticClass:"event__time-point"}),_c('div',{staticClass:"event__time-bar"}),(idx === _vm.$t('about.timeline.events').length - 1)?_c('div',{staticClass:"event__time-bar--blur"}):_vm._e()]),_c('div',{class:[
        'event__company',
        { 'first-column': idx % 2 !== 0, 'third-column': idx % 2 === 0 }
      ]},[_c('div',{staticClass:"event__company-name"},[_vm._v(_vm._s(event.company))]),_c('div',{staticClass:"event__company-description"},[_vm._v(" "+_vm._s(event['company-description'])+" ")]),_c('div',{staticClass:"event__company-job"},[_vm._v(_vm._s(event.job))])])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }